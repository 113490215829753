import React, { useState, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import { Button } from './catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from './catalyst/dialog';
import { Field, Label } from './catalyst/fieldset';
import { Input } from './catalyst/input';

const AddWorkspaceDialog = ({ isOpen, onClose, onWorkspaceAdded }) => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState(''); // Nieuwe state voor de website URL

  const handleAddWorkspace = async () => {
    console.log("handleAddWorkspace gestart");

    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (!user) {
      console.error("No user found.");
      return;
    }

    if (!workspaceName.trim()) {
      console.error("Workspace name cannot be empty.");
      return;
    }

    try {
      const { data, error } = await supabase
        .from('workspaces')
        .insert([
          { 
            name: workspaceName, 
            user_id: user.id,
            website_url: websiteUrl, // Voeg de website URL toe bij het invoegen van de workspace
          }
        ])
        .select(); // Voeg deze regel toe om de ingevoegde rijen op te halen

      if (error) {
        console.error('Error adding workspace:', error.message);
        return;
      }

      if (!data || data.length === 0) {
        console.error('No data returned after insert');
        return;
      }

      console.log('Workspace toegevoegd:', data);

      // Haal admins op en wijs ze toe aan de nieuwe workspace
      await addAdminsToWorkspace(data[0].id);

      onWorkspaceAdded(); // Notify parent component that a workspace was added
      setWorkspaceName(''); // Clear the input field
      setWebsiteUrl(''); // Clear the website URL field
      onClose(); // Close the dialog
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const addAdminsToWorkspace = useCallback(async (workspaceId) => {
    console.log('Start toewijzen admins aan workspace:', workspaceId);

    try {
      const { data: adminUsers, error } = await supabase
        .from('user_meta')
        .select('id, role')
        .eq('role', 'admin');

      if (error) {
        console.error('Error fetching admin users:', error.message);
        return;
      }

      if (adminUsers.length > 0) {
        const adminAssignments = adminUsers.map(admin => ({
          workspace_id: workspaceId,
          user_id: admin.id,
          role: admin.role,
        }));

        const { error: assignError } = await supabase
          .from('workspace_users')
          .insert(adminAssignments);

        if (assignError) {
          console.error('Error assigning admins to workspace:', assignError.message);
        } else {
          console.log('Admins toegewezen aan workspace:', workspaceId);
        }
      } else {
        console.log('Geen admins gevonden om toe te wijzen');
      }
    } catch (error) {
      console.error('Error assigning admins:', error.message);
    }
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Workspace toevoegen</DialogTitle>
      <DialogDescription>
        Vul de naam en de website URL in van de nieuwe workspace die je wilt toevoegen.
      </DialogDescription>
      <DialogBody>
        <Field className="mb-4">
          <Label>Workspace naam</Label>
          <Input
            name="workspaceName"
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
            placeholder="Voer de naam in"
          />
        </Field>
        <Field>
          <Label>Website URL</Label>
          <Input
            name="websiteUrl"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            placeholder="Voer de website URL in"
          />
        </Field>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>
          Annuleren
        </Button>
        <Button onClick={handleAddWorkspace}>Toevoegen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWorkspaceDialog;
