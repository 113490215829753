// src/components/ResetPassword.jsx
import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { Button } from './catalyst/button';
import { Input } from './catalyst/input';
import { Field, FieldGroup, Label, ErrorMessage } from './catalyst/fieldset';

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);

    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) {
      setError('Er is iets misgegaan. Probeer het opnieuw.');
    } else {
      setMessage('Als dit e-mailadres is geregistreerd, ontvangt u een e-mail met instructies om uw wachtwoord opnieuw in te stellen.');
    }
  };

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-white dark:bg-gray-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="Loile Workflow"
          src="https://tailwindui.com/img/logos/mark.svg?color=emerald&shade=600"
          className="mx-auto h-10 w-auto"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
          Wachtwoord opnieuw instellen
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleResetPassword} className="space-y-6">
          <FieldGroup>
            <Field>
              <Label htmlFor="email" className="text-gray-900 dark:text-white">E-mailadres</Label>
              <Input
                id="email"
                name="email"
                type="email"
                required
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Field>

            <Button type="submit" className="w-full bg-slate-600 text-white py-2 rounded dark:bg-slate-800">
              Verstuur reset link
            </Button>
          </FieldGroup>
        </form>

        {message && (
          <p className="mt-10 text-center text-sm text-gray-500 dark:text-gray-400">
            {message}
          </p>
        )}
      </div>
    </div>
  );
}
