import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from './catalyst/dialog';
import { Field, Label } from './catalyst/fieldset';
import { Input } from './catalyst/input';
import { Button } from './catalyst/button';

const EditWorkspaceDialog = ({ isOpen, onClose, onWorkspaceUpdated, workspace }) => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState(''); // Nieuwe state voor de website URL

  useEffect(() => {
    if (workspace) {
      setWorkspaceName(workspace.name || ''); // Vul de input met de huidige naam van de workspace
      setWebsiteUrl(workspace.website_url || ''); // Vul de input met de huidige website URL van de workspace
    } else {
      setWorkspaceName(''); // Maak het inputveld leeg als er geen workspace is geselecteerd
      setWebsiteUrl(''); // Maak het inputveld leeg voor de website URL
    }
  }, [workspace]);

  const handleSubmit = async () => {
    if (workspaceName.trim()) {
      onWorkspaceUpdated({ ...workspace, name: workspaceName, website_url: websiteUrl }); // Update de workspace inclusief de website URL
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Workspace bewerken</DialogTitle>
      <DialogDescription>Pas de naam en de website URL van de geselecteerde workspace aan.</DialogDescription>
      <DialogBody>
        <Field className="mb-4">
          <Label>Workspace naam</Label>
          <Input
            name="workspaceName"
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
            placeholder="Voer de nieuwe naam in"
          />
        </Field>
        <Field>
          <Label>Website URL</Label>
          <Input
            name="websiteUrl"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            placeholder="Voer de nieuwe website URL in"
          />
        </Field>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>Annuleren</Button>
        <Button onClick={handleSubmit}>Opslaan</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditWorkspaceDialog;
