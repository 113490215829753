import React from 'react';
import { StackedLayout } from './catalyst/stacked-layout';
import { Navbar, NavbarItem, NavbarSection, NavbarDivider, NavbarSpacer } from './catalyst/navbar';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu, DropdownDivider } from './catalyst/dropdown';
import { UserIcon, Cog8ToothIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/16/solid';

const AdminNavbarMenu = () => (
  <Navbar>
    <NavbarItem href="/" aria-label="Home">
      Loile Workflow
    </NavbarItem>
    <NavbarDivider />
    <NavbarSection>
      <NavbarItem href="/admin" current={window.location.pathname === "/admin"}>
        Workspaces
      </NavbarItem>
      <NavbarItem href="/admin/users" current={window.location.pathname === "/admin/users"}>
        Gebruikers
      </NavbarItem>
    </NavbarSection>
    
    {/* Dit zorgt ervoor dat het profiel-dropdownmenu helemaal rechts komt */}
    <NavbarSpacer />

    <NavbarSection>
      <Dropdown>
        <DropdownButton as={NavbarItem}>
          <UserIcon />
        </DropdownButton>
        <DropdownMenu anchor="bottom end">
          <DropdownItem href="/profile">
            <UserIcon />
            <span>Mijn profiel</span>
          </DropdownItem>
          <DropdownItem href="/settings">
            <Cog8ToothIcon />
            <span>Instellingen</span>
          </DropdownItem>
          <DropdownDivider />
          <DropdownItem href="/logout">
            <ArrowRightStartOnRectangleIcon />
            <span>Sign out</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </NavbarSection>
  </Navbar>
);

const AdminLayout = ({ children }) => {
  return (
    <StackedLayout
      navbar={<AdminNavbarMenu />}
    >
      <div className="p-4">
        {children}
      </div>
    </StackedLayout>
  );
};

export default AdminLayout;
