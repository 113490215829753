import React from 'react';
import { useAuth } from './AuthContext';
import Login from './Login'; // Zorg ervoor dat je de Login-component importeert

const ProtectedRoute = ({ children }) => {
  const user = useAuth();

  if (user === null) {
    return <Login />; // Render de Login component als de gebruiker niet ingelogd is
  }

  return user ? children : <Login />; // Render opnieuw de Login component als er geen user is
};

export default ProtectedRoute;
