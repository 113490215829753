// src/components/Login.jsx
import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from './catalyst/button';
import { Input } from './catalyst/input';
import { Field, FieldGroup, Label, ErrorMessage } from './catalyst/fieldset';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError(null);
    setPasswordError(null);

    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      if (error.message.includes('Invalid login credentials')) {
        setPasswordError('Wachtwoord is incorrect.');
      } else if (error.message.includes('Invalid email or password')) {
        setEmailError('E-mailadres is incorrect.');
      } else {
        setEmailError('Er is iets misgegaan. Probeer het opnieuw.');
      }
    } else {
      navigate('/');
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-white dark:bg-gray-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="Loile Workflow"
          src="https://tailwindui.com/img/logos/mark.svg?color=emerald&shade=600"
          className="mx-auto h-10 w-auto"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
          Workflow
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleLogin} className="space-y-6">
          <FieldGroup>
            <Field>
              <Label htmlFor="email" className="text-gray-900 dark:text-white">E-mailadres</Label>
              <Input
                id="email"
                name="email"
                type="email"
                required
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                invalid={!!emailError}
                className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
              />
              {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
            </Field>

            <Field>
              <Label htmlFor="password" className="text-gray-900 dark:text-white">Wachtwoord</Label>
              <Input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                invalid={!!passwordError}
                className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
              />
              {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
            </Field>

            <Button type="submit" className="w-full bg-slate-600 text-white py-2 rounded dark:bg-slate-800">
              Inloggen
            </Button>
          </FieldGroup>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500 dark:text-gray-400">
          <Link to="/reset-password" className="leading-6 text-emerald-600 hover:text-emerald-500 dark:text-emerald-400 dark:hover:text-emerald-300">
            Wachtwoord vergeten?
          </Link>
        </p>
      </div>
    </div>
  );
}
