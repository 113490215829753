import React, { useState, useEffect } from 'react';
import { Dialog, DialogBody, DialogActions, DialogTitle } from '../catalyst/dialog';
import { Field, Label } from '../catalyst/fieldset';
import { Input } from '../catalyst/input';
import { Button } from '../catalyst/button';
import { supabase } from '../../supabaseClient';

const ConnectGoogleSearchConsoleDialog = ({ isOpen, onClose, workspaceId, onConnect }) => {
  const [siteUrl, setSiteUrl] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!workspaceId) {
      console.error('Workspace ID is not defined');
      return;
    }

    const fetchWorkspaceData = async () => {
      const { data, error } = await supabase
        .from('workspaces')
        .select('google_search_console_site_url')
        .eq('id', workspaceId)
        .single();

      if (error) {
        console.error('Error fetching workspace data:', error.message);
      } else if (data.google_search_console_site_url) {
        setSiteUrl(data.google_search_console_site_url);
        setIsConnected(true);
      }
    };

    if (isOpen) {
      fetchWorkspaceData();
    }
  }, [isOpen, workspaceId]);

  const handleSave = async () => {
    if (!workspaceId) {
      console.error('Workspace ID is not defined');
      return;
    }

    const { error } = await supabase
      .from('workspaces')
      .update({ google_search_console_site_url: siteUrl })
      .eq('id', workspaceId);

    if (error) {
      console.error('Error updating workspace:', error.message);
    } else {
      onConnect(); // Callback om de interface te updaten
      onClose();   // Sluit de dialog
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Google Search Console Koppeling</DialogTitle>
      <DialogBody>
        <p>Voer je Google Search Console Site URL in om de koppeling te maken.</p>
        <Field className="mt-4">
          <Label>Google Search Console - Site URL</Label>
          <Input
            value={siteUrl}
            onChange={(e) => setSiteUrl(e.target.value)}
            placeholder="https://www.example.com"
          />
        </Field>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>Annuleren</Button>
        <Button onClick={handleSave}>{isConnected ? 'Bewerken' : 'Koppelen'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConnectGoogleSearchConsoleDialog;
