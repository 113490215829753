import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Button } from '../components/catalyst/button';
import AddWorkspaceDialog from '../components/AddWorkspaceDialog';
import EditWorkspaceDialog from '../components/EditWorkspaceDialog'; // Importeer de nieuwe EditWorkspaceDialog

const Superspace = () => {
  const [user, setUser] = useState(null);
  const [workspaces, setWorkspaces] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUser(user);
        fetchWorkspaces(user.id);
      } else {
        console.log('No user found');
      }
    };
    fetchUser();
  }, []);

  const fetchWorkspaces = async (userId) => {
    try {
      const { data, error } = await supabase.from('workspaces').select('*').eq('user_id', userId);
      if (error) {
        console.error('Error fetching workspaces:', error.message);
      } else {
        setWorkspaces(data);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleWorkspaceAdded = () => {
    if (user) fetchWorkspaces(user.id);
  };

  const handleWorkspaceUpdated = async (updatedWorkspace) => {
    try {
      const { error } = await supabase.from('workspaces').update({ name: updatedWorkspace.name }).eq('id', updatedWorkspace.id);
      if (error) {
        console.error('Error updating workspace:', error.message);
      } else {
        fetchWorkspaces(user.id);
        setIsEditDialogOpen(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const openEditDialog = (workspace) => {
    setSelectedWorkspace(workspace);
    setIsEditDialogOpen(true);
  };

  const handleDeleteWorkspace = async (workspaceId) => {
    try {
      const { error } = await supabase.from('workspaces').delete().eq('id', workspaceId);
      if (error) {
        console.error('Error deleting workspace:', error.message);
      } else {
        fetchWorkspaces(user.id);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div className="p-4">
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <h1>Superspace</h1>
        <Button type="button" onClick={() => setIsAddDialogOpen(true)}>
          Workspace toevoegen
        </Button>
      </div>

      <AddWorkspaceDialog
        isOpen={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        onWorkspaceAdded={handleWorkspaceAdded}
      />

      <EditWorkspaceDialog
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        onWorkspaceUpdated={handleWorkspaceUpdated}
        workspace={selectedWorkspace}
      />

      <h2 className="mt-6 text-lg font-medium">Jouw Workspaces</h2>
      <ul className="mt-4 space-y-2">
        {workspaces.map((workspace) => (
          <li key={workspace.id} className="flex justify-between items-center">
            <span>{workspace.name}</span>
            <div>
              <Button className="mr-2" onClick={() => openEditDialog(workspace)}>
                Bewerken
              </Button>
              <Button variant="danger" onClick={() => handleDeleteWorkspace(workspace.id)}>
                Verwijderen
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Superspace;
