import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import Login from './components/Login';
import Logout from './components/Logout';
import ResetPassword from './components/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import Workspaces from './pages/Workspaces';
import WorkspaceOverview from './pages/workspace/Overview';
import WorkspaceSettings from './pages/workspace/Settings';
import Superspace from './pages/Superspace';
import AdminLayout from './components/AdminLayout';
import UsersPage from './pages/admin/Users';
import Users from './pages/Users';
import WorkspacesPage from './pages/admin/Workspaces';
import { StackedLayout } from './components/catalyst/stacked-layout';
import NavbarMenu from './components/NavbarMenu';
import SidebarMenu from './components/SidebarMenu';

function Layout({ children }) {
  return (
    <StackedLayout navbar={<NavbarMenu />} sidebar={<SidebarMenu />}>
      {children}
    </StackedLayout>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          
          <Route path="/superspace" element={<ProtectedRoute roles={['admin', 'manager']}><Superspace /></ProtectedRoute>} />
          <Route path="/users" element={<ProtectedRoute roles={['admin']}><Layout><Users /></Layout></ProtectedRoute>} />
          <Route path="/:id" element={<ProtectedRoute roles={['admin']}><Layout><WorkspaceOverview /></Layout></ProtectedRoute>} />
          <Route path="/:id/settings" element={<ProtectedRoute roles={['admin']}><Layout><WorkspaceSettings /></Layout></ProtectedRoute>} />

          {/* Admin Routes */}
          <Route path="/admin" element={<ProtectedRoute roles={['admin']}><AdminLayout><WorkspacesPage /></AdminLayout></ProtectedRoute>} />
          <Route path="/admin/users" element={<ProtectedRoute roles={['admin']}><AdminLayout><UsersPage /></AdminLayout></ProtectedRoute>} />

          <Route
            path="/"
            element={
              <ProtectedRoute roles={['admin', 'manager', 'client']}>
                <Layout>
                  <Workspaces />
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
