import React, { useState, useEffect } from 'react';
import { supabaseAdmin } from '../supabaseClient'; // Aangepast voor de nieuwe locatie in de src map
import { Button } from '../components/catalyst/button'; // Aangepast voor de nieuwe locatie
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/catalyst/dialog';
import { Field, Label } from '../components/catalyst/fieldset';
import { Input } from '../components/catalyst/input';
import { Avatar } from '../components/catalyst/avatar';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/catalyst/table';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/catalyst/dropdown';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import { Heading } from '../components/catalyst/heading';

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [firstName, setFirstName] = useState(''); // Voornaam
  const [lastName, setLastName] = useState(''); // Achternaam
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userRole, setUserRole] = useState(''); // Rol
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const { data: usersData, error } = await supabaseAdmin.auth.admin.listUsers();
    if (error) {
      console.error('Error fetching users:', error.message);
    } else {
      const userIds = usersData.users.map(user => user.id);
      const { data: metaData, error: metaError } = await supabaseAdmin
        .from('user_meta')
        .select('*')
        .in('id', userIds);
  
      if (metaError) {
        console.error('Error fetching user metadata:', metaError.message);
      } else {
        const usersWithMeta = usersData.users.map(user => {
          const meta = metaData.find(m => m.id === user.id);
          return { ...user, ...meta };
        });
        setUsers(usersWithMeta);
      }
    }
  };

  const assignUserToAllWorkspaces = async (userId) => {
    const { data: workspaces, error } = await supabaseAdmin
      .from('workspaces')
      .select('id');

    if (error) {
      console.error('Error fetching workspaces:', error.message);
      return;
    }

    const assignments = workspaces.map(workspace => ({
      workspace_id: workspace.id,
      user_id: userId,
      role: 'admin', // De rol is admin
    }));

    const { error: assignError } = await supabaseAdmin
      .from('workspace_users')
      .insert(assignments);

    if (assignError) {
      console.error('Error assigning user to workspaces:', assignError.message);
    }
  };

  const removeUserFromAllWorkspaces = async (userId) => {
    const { error } = await supabaseAdmin
      .from('workspace_users')
      .delete()
      .eq('user_id', userId)
      .eq('role', 'admin'); // Verwijder alleen als de rol admin was

    if (error) {
      console.error('Error removing user from workspaces:', error.message);
    }
  };

  const handleAddUser = async () => {
    if (!userEmail.trim() || !userPassword.trim()) {
      console.error("Email en wachtwoord mogen niet leeg zijn.");
      return;
    }

    const displayName = `${firstName} ${lastName}`;

    try {
      const { data: newUser, error: authError } = await supabaseAdmin.auth.admin.createUser({
        email: userEmail,
        password: userPassword,
        email_confirm: true,
        user_metadata: {
          full_name: displayName, // Display Name in auth table
        },
      });

      if (authError) {
        console.error('Error adding user:', authError.message);
        return;
      }

      // Insert into the user_meta table
      const { error: metaError } = await supabaseAdmin
        .from('user_meta')
        .insert({
          id: newUser.user.id,
          first_name: firstName,
          last_name: lastName,
          role: userRole, // Alleen de rol
        });

      if (metaError) {
        console.error('Error adding user metadata:', metaError.message);
      } else {
        // Als de gebruiker Admin is, wijs hem/haar toe aan alle workspaces
        if (userRole === 'admin') {
          await assignUserToAllWorkspaces(newUser.user.id);
        }

        resetFormFields(); // Reset form fields
        setIsAddDialogOpen(false);
        fetchUsers();
        console.log('User successfully added with metadata');
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleEditUser = async () => {
    if (editingUser.role === 'admin' && userRole !== 'admin') {
      // Als de rol verandert van admin naar iets anders, verwijder de toewijzing
      await removeUserFromAllWorkspaces(editingUser.id);
    } else if (editingUser.role !== 'admin' && userRole === 'admin') {
      // Als de rol verandert naar admin, wijs de gebruiker toe aan alle workspaces
      await assignUserToAllWorkspaces(editingUser.id);
    }

    const { error: metaError } = await supabaseAdmin
      .from('user_meta')
      .update({
        first_name: firstName,
        last_name: lastName,
        role: userRole, // Alleen de rol
      })
      .eq('id', editingUser.id);

    if (metaError) {
      console.error('Error updating user metadata:', metaError.message);
    } else {
      resetFormFields(); // Reset form fields
      setEditingUser(null);
      setIsAddDialogOpen(false);
      fetchUsers();
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      // Verwijder de gebruiker uit de workspace_users-tabel
      const { error: removeWorkspaceError } = await supabaseAdmin
        .from('workspace_users')
        .delete()
        .eq('user_id', userId);

      if (removeWorkspaceError) {
        console.error('Error removing user from workspaces:', removeWorkspaceError.message);
        return;
      }

      // Verwijder de gebruiker uit de user_meta-tabel
      const { error: metaError } = await supabaseAdmin
        .from('user_meta')
        .delete()
        .eq('id', userId);

      if (metaError) {
        console.error('Error deleting user metadata:', metaError.message);
        return;
      }

      // Verwijder de gebruiker uit de Supabase authenticatie
      const { error: authError } = await supabaseAdmin.auth.admin.deleteUser(userId);

      if (authError) {
        console.error('Error deleting user:', authError.message);
      } else {
        fetchUsers(); // Werk de lijst met gebruikers bij
      }
    } catch (error) {
      console.error('Error deleting user:', error.message);
    }
  };

  const openEditDialog = (user) => {
    setEditingUser(user);
    setFirstName(user.first_name || '');
    setLastName(user.last_name || '');
    setUserEmail(user.email);
    setUserRole(user.role || ''); // Alleen de rol
    setIsAddDialogOpen(true);
  };

  const openAddDialog = () => {
    resetFormFields(); // Reset form fields
    setEditingUser(null);
    setIsAddDialogOpen(true);
  };

  const resetFormFields = () => {
    setFirstName('');
    setLastName('');
    setUserEmail('');
    setUserPassword('');
    setUserRole('');
  };

  return (
    <div className="p-4">
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Gebruikers</Heading>
        <Button type="button" onClick={openAddDialog}>
          Gebruiker toevoegen
        </Button>
      </div>

      <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] mt-4">
        <TableHead>
          <TableRow>
            <TableHeader>Naam</TableHeader>
            <TableHeader>E-mail</TableHeader>
            <TableHeader>Rol</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader className="relative w-0">
              <span className="sr-only">Acties</span>
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                <div className="flex items-center gap-4">
                  <Avatar 
                    initials={`${user.first_name ? user.first_name.charAt(0) : ''}${user.last_name ? user.last_name.charAt(0) : ''}`} 
                    className="size-8 bg-zinc-900 text-white dark:bg-white dark:text-black" 
                  />
                <div>
                    <div className="font-medium">{`${user.first_name} ${user.last_name}`}</div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="text-zinc-500">{user.email}</TableCell>
              <TableCell className="text-zinc-500">{user.role}</TableCell>
              <TableCell className="text-zinc-500">
                {user.last_sign_in_at ? new Date(user.last_sign_in_at).toLocaleString() : 'Nooit ingelogd'}
              </TableCell>
              <TableCell>
                <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                  <Dropdown>
                    <DropdownButton plain aria-label="Meer opties">
                      <EllipsisHorizontalIcon className="h-5 w-5 text-zinc-500" />
                    </DropdownButton>
                    <DropdownMenu anchor="bottom end">
                      <DropdownItem onClick={() => openEditDialog(user)}>Bewerken</DropdownItem>
                      <DropdownItem onClick={() => handleDeleteUser(user.id)} color="danger">Verwijderen</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
        <DialogTitle>{editingUser ? 'Gebruiker bewerken' : 'Gebruiker toevoegen'}</DialogTitle>
        <DialogDescription>
          {editingUser ? 'Pas de gegevens van de gebruiker aan.' : 'Vul de gegevens in van de nieuwe gebruiker die je wilt toevoegen.'}
        </DialogDescription>
        <DialogBody>
          <div className="flex gap-4">
            <Field className="w-1/2 mb-4">
              <Label>Voornaam</Label>
              <Input
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Voornaam"
              />
            </Field>
            <Field className="w-1/2 mb-4">
              <Label>Achternaam</Label>
              <Input
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Achternaam"
              />
            </Field>
          </div>
          <Field className="mb-4">
            <Label>Email</Label>
            <Input
              name="userEmail"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder="Voer het e-mailadres in"
              disabled={!!editingUser} // Disable het emailveld bij bewerken
            />
          </Field>
          {!editingUser && (
            <Field className="mb-4">
              <Label>Wachtwoord</Label>
              <Input
                type="password"
                name="userPassword"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                placeholder="Voer het wachtwoord in"
              />
            </Field>
          )}
          <Field className="mb-4">
            <Label>Rol</Label>
            <select
              name="userRole"
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Selecteer een rol</option>
              <option value="admin">Admin</option>
              <option value="manager">Manager</option>
              <option value="client">Client</option>
            </select>
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsAddDialogOpen(false)}>
            Annuleren
          </Button>
          <Button onClick={editingUser ? handleEditUser : handleAddUser}>
            {editingUser ? 'Bewerken' : 'Toevoegen'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersPage;
