import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Heading } from '../../components/catalyst/heading';
import { Button } from '../../components/catalyst/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/catalyst/table';
import ConnectGoogleAnalyticsDialog from '../../components/connectors/ConnectGoogleAnalyticsDialog';
import ConnectGoogleSearchConsoleDialog from '../../components/connectors/ConnectGoogleSearchConsoleDialog';
import ConnectUptimeRobotDialog from '../../components/connectors/ConnectUptimeRobotDialog'; // Voeg de nieuwe dialog import toe
import { supabase } from '../../supabaseClient';

const services = [
  { 
    name: 'Google Analytics', 
    description: 'Analyseer websiteverkeer en gebruikersgedrag.' 
  },
  { 
    name: 'Google Search Console', 
    description: 'Analyseer zoekprestaties van je website in Google Zoeken.' 
  },
  { 
    name: 'Uptime Robot', 
    description: 'Controleer de status van je website.' 
  },
];

const WorkspaceSettings = () => {
  const { id } = useParams();  // Gebruik useParams om de id uit de URL te halen
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [isGAConnected, setIsGAConnected] = useState(false);
  const [isGSCConnected, setIsGSCConnected] = useState(false);
  const [isURConnected, setIsURConnected] = useState(false); // Nieuw state voor Uptime Robot

  useEffect(() => {
    console.log('Workspace ID:', id);  // Log de waarde van id om te zien of het correct wordt opgehaald

    const checkConnection = async () => {
      if (!id) {
        console.error('Workspace ID is not defined');
        return;
      }

      const { data, error } = await supabase
        .from('workspaces')
        .select('google_analytics_property_id, google_search_console_site_url, uptime_robot_api_key')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching workspace data:', error.message);
      } else {
        if (data.google_analytics_property_id) {
          setIsGAConnected(true);
        }
        if (data.google_search_console_site_url) {
          setIsGSCConnected(true);
        }
        if (data.uptime_robot_api_key) {
          setIsURConnected(true);
        }
      }
    };

    if (id) {
      checkConnection();
    }
  }, [id]);

  const openDialog = (service) => {
    setSelectedService(service);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedService(null);
  };

  const handleConnect = (service) => {
    if (service === 'Google Analytics') {
      setIsGAConnected(true);
    } else if (service === 'Google Search Console') {
      setIsGSCConnected(true);
    } else if (service === 'Uptime Robot') {
      setIsURConnected(true);
    }
    closeDialog();
  };

  return (
    <div className="p-4">
      <Heading>Instellingen</Heading>

      <Table className="min-w-full mt-4">
        <TableHead>
          <TableRow>
            <TableHeader>Service</TableHeader>
            <TableHeader className="text-right">Acties</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service, index) => (
            <TableRow key={index}>
              <TableCell>
                <h2 className="font-medium text-zinc-900">{service.name}</h2>
                <p className="text-zinc-600">{service.description}</p>
              </TableCell>
              <TableCell className="text-right">
                <Button onClick={() => openDialog(service.name)}>
                  {(service.name === 'Google Analytics' && isGAConnected) || 
                  (service.name === 'Google Search Console' && isGSCConnected) ||
                  (service.name === 'Uptime Robot' && isURConnected)
                    ? 'Bewerken'
                    : 'Koppelen'}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedService === 'Google Analytics' && (
        <ConnectGoogleAnalyticsDialog
          isOpen={isDialogOpen}
          onClose={closeDialog}
          workspaceId={id}
          onConnect={() => handleConnect('Google Analytics')}
        />
      )}

      {selectedService === 'Google Search Console' && (
        <ConnectGoogleSearchConsoleDialog
          isOpen={isDialogOpen}
          onClose={closeDialog}
          workspaceId={id}
          onConnect={() => handleConnect('Google Search Console')}
        />
      )}

      {selectedService === 'Uptime Robot' && (
        <ConnectUptimeRobotDialog
          isOpen={isDialogOpen}
          onClose={closeDialog}
          workspaceId={id}
          onConnect={() => handleConnect('Uptime Robot')}
        />
      )}
    </div>
  );
};

export default WorkspaceSettings;
