// src/components/Logout.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const signOutUser = async () => {
      await supabase.auth.signOut();
      navigate('/login');
    };

    signOutUser();
  }, [navigate]);

  return <div>Logging out...</div>;
}
