import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Sidebar, SidebarBody, SidebarHeader, SidebarItem, SidebarSection, SidebarLabel } from './catalyst/sidebar';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from './catalyst/dropdown';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import AddWorkspaceDialog from './AddWorkspaceDialog';
import { Avatar } from './catalyst/avatar';

const navItems = [
  { label: 'Workspaces', url: '/' },
  { label: 'Gebruikers', url: '/users' },
];

const TeamDropdownMenu = ({ workspaces, onAddWorkspaceClick }) => {
  return (
    <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
      {workspaces.map((workspace) => (
        <DropdownItem key={workspace.id} href={`/workspace/${workspace.id}`}>
          {workspace.name}
        </DropdownItem>
      ))}
      <DropdownItem href="/">
        Workspace Overzicht
      </DropdownItem>
    </DropdownMenu>
  );
};

const SidebarMenu = () => {
  const location = useLocation();
  const [workspaces, setWorkspaces] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('workspaces')
          .select('*')
          .eq('user_id', user.id);
        
        if (error) {
          console.error('Error fetching workspaces:', error.message);
        } else {
          setWorkspaces(data);
        }
      }
    };

    fetchWorkspaces();
  }, []);

  return (
    <>
      <Sidebar>
        <SidebarHeader>
          <Dropdown>
            <DropdownButton as={SidebarItem} className="lg:mb-2.5">
              <Avatar src="/tailwind-logo.svg" />
              <SidebarLabel>Tailwind Labs</SidebarLabel>
              <ChevronDownIcon />
            </DropdownButton>
            <TeamDropdownMenu
              workspaces={workspaces}
              onAddWorkspaceClick={() => setIsAddDialogOpen(true)}
            />
          </Dropdown>
        </SidebarHeader>
        <SidebarBody>
          <SidebarSection>
            {navItems.map(({ label, url }) => (
              <SidebarItem key={label} href={url} current={location.pathname === url}>
                {label}
              </SidebarItem>
            ))}
            {workspaces.map((workspace) => (
              <SidebarItem key={workspace.id} href={`/workspace/${workspace.id}`} current={location.pathname === `/workspace/${workspace.id}`}>
                {workspace.name}
              </SidebarItem>
            ))}
          </SidebarSection>
        </SidebarBody>
      </Sidebar>

      <AddWorkspaceDialog
        isOpen={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        onWorkspaceAdded={() => { /* handle reloading workspaces */ }}
      />
    </>
  );
};

export default SidebarMenu;
