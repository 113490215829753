import React, { useState, useEffect, useCallback } from 'react';
import { supabaseAdmin } from '../supabaseClient';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/catalyst/dialog';
import { Button } from '../components/catalyst/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/catalyst/table';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/catalyst/dropdown';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid';

const AssignUserDialog = ({ isOpen, onClose, workspaceId, onUserAssigned }) => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [assignedUsers, setAssignedUsers] = useState([]);

  const addAdminsToWorkspace = useCallback(async (adminUsers) => {
    const adminAssignments = adminUsers.map(admin => ({
      workspace_id: workspaceId,
      user_id: admin.id,
      role: admin.role,
    }));

    const { error } = await supabaseAdmin
      .from('workspace_users')
      .insert(adminAssignments);

    if (error) {
      console.error('Error assigning admins:', error.message);
    }
  }, [workspaceId]);

  const fetchAssignedUsers = useCallback(async () => {
    if (!workspaceId) return;

    try {
      const { data, error } = await supabaseAdmin
        .from('workspace_users')
        .select('user_id')
        .eq('workspace_id', workspaceId);

      if (error) {
        console.error('Error fetching assigned users:', error.message);
        return;
      }

      const userIds = data.map(assigned => assigned.user_id);
      setAssignedUsers(userIds);

      // Voeg admins toe als ze nog niet zijn toegewezen
      const adminUsers = users.filter(user => user.role === 'admin' && !userIds.includes(user.id));
      if (adminUsers.length > 0) {
        await addAdminsToWorkspace(adminUsers);
        fetchAssignedUsers(); // Refresh the list after adding admins
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  }, [workspaceId, users, addAdminsToWorkspace]);

  useEffect(() => {
    fetchAssignedUsers();
  }, [fetchAssignedUsers]);

  const fetchUsers = async () => {
    const { data, error } = await supabaseAdmin
      .from('user_meta')
      .select('id, first_name, last_name, role');
    
    if (error) {
      console.error('Error fetching users:', error.message);
    } else {
      setUsers(data);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAssignUser = async () => {
    if (!selectedUserId) {
      console.error('Selecteer een gebruiker.');
      return;
    }

    try {
      const { error } = await supabaseAdmin
        .from('workspace_users')
        .insert({
          workspace_id: workspaceId,
          user_id: selectedUserId,
          role: users.find(user => user.id === selectedUserId)?.role,
        });

      if (error) {
        console.error('Error assigning user:', error.message);
      } else {
        fetchAssignedUsers();
        onUserAssigned();
        setSelectedUserId('');
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleDeleteAssignedUser = async (userId) => {
    const { error } = await supabaseAdmin
      .from('workspace_users')
      .delete()
      .eq('workspace_id', workspaceId)
      .eq('user_id', userId);

    if (error) {
      console.error('Error removing assigned user:', error.message);
    } else {
      fetchAssignedUsers();
    }
  };

  const availableUsers = users.filter(user => !assignedUsers.includes(user.id) && user.role !== 'admin');

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Gebruikers toewijzen aan workspace</DialogTitle>
      <DialogDescription>Wijs gebruikers toe aan deze workspace en beheer toegewezen gebruikers.</DialogDescription>
      <DialogBody>
        <div className="flex items-center gap-4">
          <div className="flex-grow">
            <select
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
              className="w-full p-2 border rounded h-full"
            >
              <option value="">Selecteer een gebruiker</option>
              {availableUsers.map((user) => (
                <option key={user.id} value={user.id}>
                  {`${user.first_name} ${user.last_name} - ${user.role}`}
                </option>
              ))}
            </select>
          </div>
          <Button onClick={handleAssignUser} className="h-full" style={{ lineHeight: '1.9rem' }}>
            Toewijzen
          </Button>
        </div>
        <Table className="mt-4">
          <TableHead>
            <TableRow>
              <TableHeader>Naam</TableHeader>
              <TableHeader>Rol</TableHeader>
              <TableHeader className="relative w-0">
                <span className="sr-only">Acties</span>
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignedUsers.map((userId) => {
              const user = users.find(u => u.id === userId);
              return (
                <TableRow key={userId}>
                  <TableCell>
                    <div className="flex items-center gap-4">
                      <div>
                        <div className="font-medium">{`${user?.first_name || ''} ${user?.last_name || ''}`}</div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="text-zinc-500">{user?.role || ''}</TableCell>
                  <TableCell>
                    {user?.role !== 'admin' && (
                      <Dropdown>
                        <DropdownButton plain aria-label="Meer opties">
                          <EllipsisHorizontalIcon className="h-5 w-5 text-zinc-500" />
                        </DropdownButton>
                        <DropdownMenu anchor="bottom end">
                          <DropdownItem onClick={() => handleDeleteAssignedUser(userId)} color="danger">
                            Verwijderen
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignUserDialog;
