import React, { useState, useEffect } from 'react';
import { Dialog, DialogBody, DialogActions, DialogTitle } from '../catalyst/dialog';
import { Field, Label } from '../catalyst/fieldset';
import { Input } from '../catalyst/input';
import { Button } from '../catalyst/button';
import { supabase } from '../../supabaseClient';

const ConnectUptimeRobotDialog = ({ isOpen, onClose, workspaceId, onConnect }) => {
  const [apiKey, setApiKey] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!workspaceId) {
      console.error('Workspace ID is not defined');
      return;
    }

    const fetchWorkspaceData = async () => {
      const { data, error } = await supabase
        .from('workspaces')
        .select('uptime_robot_api_key')
        .eq('id', workspaceId)
        .single();

      if (error) {
        console.error('Error fetching workspace data:', error.message);
      } else if (data.uptime_robot_api_key) {
        setApiKey(data.uptime_robot_api_key);
        setIsConnected(true);
      }
    };

    if (isOpen) {
      fetchWorkspaceData();
    }
  }, [isOpen, workspaceId]);

  const handleSave = async () => {
    if (!workspaceId) {
      console.error('Workspace ID is not defined');
      return;
    }

    const { error } = await supabase
      .from('workspaces')
      .update({ uptime_robot_api_key: apiKey })
      .eq('id', workspaceId);

    if (error) {
      console.error('Error updating workspace:', error.message);
    } else {
      onConnect(); // Callback om de interface te updaten
      onClose();   // Sluit de dialog
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Uptime Robot Koppeling</DialogTitle>
      <DialogBody>
        <p>Voer je Uptime Robot API-sleutel in om de koppeling te maken.</p>
        <Field className="mt-4">
          <Label>Uptime Robot API Key</Label>
          <Input
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="Jouw API-sleutel"
          />
        </Field>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>Annuleren</Button>
        <Button onClick={handleSave}>{isConnected ? 'Bewerken' : 'Koppelen'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConnectUptimeRobotDialog;
