import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { supabaseAdmin } from '../supabaseClient';
import { Button } from '../components/catalyst/button';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/catalyst/dropdown';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import { Heading } from '../components/catalyst/heading';
import { Avatar } from '../components/catalyst/avatar';
import AssignUserDialog from '../components/AssignUserDialog';
import AddWorkspaceDialog from '../components/AddWorkspaceDialog';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/catalyst/dialog';
import { Field, Label } from '../components/catalyst/fieldset';
import { Input } from '../components/catalyst/input';

const StartPage = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editingWorkspace, setEditingWorkspace] = useState(null);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null);
  const [workspaceName, setWorkspaceName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');

  const navigate = useNavigate(); // Gebruik useNavigate om te navigeren

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  const fetchWorkspaces = async () => {
    try {
      const { data: workspacesData, error } = await supabaseAdmin
        .from('workspaces')
        .select('id, name, website_url');

      if (error) {
        throw error;
      }

      const workspacesWithUsers = await Promise.all(
        workspacesData.map(async (workspace) => {
          const { data: assignedUsers, error: userError } = await supabaseAdmin
            .from('workspace_users')
            .select('user_id, user_meta (first_name, last_name)')
            .eq('workspace_id', workspace.id);

          if (userError) {
            throw userError;
          }

          return {
            ...workspace,
            users: assignedUsers.map((assigned) => ({
              first_name: assigned.user_meta.first_name,
              last_name: assigned.user_meta.last_name,
              initials: `${assigned.user_meta.first_name.charAt(0)}${assigned.user_meta.last_name.charAt(0)}`,
            })),
          };
        })
      );

      // Sorteer de workspaces op naam, alfabetisch
      const sortedWorkspaces = workspacesWithUsers.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setWorkspaces(sortedWorkspaces);
    } catch (error) {
      console.error('Error fetching workspaces:', error.message);
    }
  };

  const handleDeleteWorkspace = async (workspaceId) => {
    try {
      const { error: deleteUsersError } = await supabaseAdmin
        .from('workspace_users')
        .delete()
        .eq('workspace_id', workspaceId);

      if (deleteUsersError) {
        throw deleteUsersError;
      }

      const { error: deleteWorkspaceError } = await supabaseAdmin
        .from('workspaces')
        .delete()
        .eq('id', workspaceId);

      if (deleteWorkspaceError) {
        throw deleteWorkspaceError;
      }

      fetchWorkspaces();
    } catch (error) {
      console.error('Error deleting workspace:', error.message);
    }
  };

  const handleAssignUser = () => {
    fetchWorkspaces();
  };

  const openEditDialog = (workspace) => {
    setEditingWorkspace(workspace);
    setWorkspaceName(workspace.name);
    setWebsiteUrl(workspace.website_url);
    setIsEditDialogOpen(true);
  };

  const openAssignDialog = (workspaceId) => {
    setSelectedWorkspaceId(workspaceId);
    setIsAssignDialogOpen(true);
  };

  const openAddDialog = () => {
    setIsAddDialogOpen(true);
  };

  const handleEditWorkspace = async () => {
    if (!workspaceName.trim()) {
      console.error("Workspace name cannot be empty.");
      return;
    }

    try {
      const { error } = await supabaseAdmin
        .from('workspaces')
        .update({ name: workspaceName, website_url: websiteUrl })
        .eq('id', editingWorkspace.id);

      if (error) {
        throw error;
      }

      fetchWorkspaces();
      setIsEditDialogOpen(false);
    } catch (error) {
      console.error('Error updating workspace:', error.message);
    }
  };

  const handleWorkspaceClick = (id) => {
    navigate(`/${id}`); 
  };

  return (
    <div className="p-4">
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Workspaces</Heading>
        <Button type="button" onClick={openAddDialog}>
          Workspace toevoegen
        </Button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {workspaces.map((workspace) => (
          <div key={workspace.id} className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
            <div className="flex justify-between items-center">
              <h2 className="font-semibold cursor-pointer text-zinc-900" onClick={() => handleWorkspaceClick(workspace.id)}>
                {workspace.name}
              </h2>
              <Dropdown>
                <DropdownButton plain aria-label="Meer opties">
                  <EllipsisHorizontalIcon className="h-5 w-5 text-zinc-500" />
                </DropdownButton>
                <DropdownMenu anchor="bottom end">
                  <DropdownItem onClick={() => openEditDialog(workspace)}>Bewerken</DropdownItem>
                  <DropdownItem onClick={() => openAssignDialog(workspace.id)}>Gebruikers beheren</DropdownItem>
                  <DropdownItem onClick={() => handleDeleteWorkspace(workspace.id)} color="danger">Verwijderen</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <p className="text-zinc-500 mt-0">{workspace.website_url}</p>
            <div className="text-zinc-500 mt-4">
              {workspace.users && workspace.users.length > 0 ? (
                <div className="flex items-center justify-start mt-2 -space-x-2">
                  {workspace.users.map((user, index) => (
                    <Avatar 
                      key={index} 
                      initials={user.initials} 
                      className="size-8 bg-zinc-900 text-white dark:bg-white dark:text-black ring-2 ring-white dark:ring-zinc-900" 
                    />
                  ))}
                </div>
              ) : (
                <p className="text-zinc-500">Geen toegewezen gebruikers</p>
              )}
            </div>
          </div>
        ))}
      </div>

      <AddWorkspaceDialog 
        isOpen={isAddDialogOpen} 
        onClose={() => setIsAddDialogOpen(false)} 
        onWorkspaceAdded={fetchWorkspaces} 
      />

      <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
        <DialogTitle>Workspace bewerken</DialogTitle>
        <DialogDescription>Pas de naam en de website URL van de geselecteerde workspace aan.</DialogDescription>
        <DialogBody>
          <Field className="mb-4">
            <Label>Workspace naam</Label>
            <Input
              name="workspaceName"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              placeholder="Voer de nieuwe naam in"
            />
          </Field>
          <Field>
            <Label>Website URL</Label>
            <Input
              name="websiteUrl"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              placeholder="Voer de nieuwe website URL in"
            />
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsEditDialogOpen(false)}>Annuleren</Button>
          <Button onClick={handleEditWorkspace}>Opslaan</Button>
        </DialogActions>
      </Dialog>

      <AssignUserDialog 
        isOpen={isAssignDialogOpen} 
        onClose={() => setIsAssignDialogOpen(false)} 
        workspaceId={selectedWorkspaceId}
        onUserAssigned={handleAssignUser} 
      />
    </div>
  );
};

export default StartPage;
