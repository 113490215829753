import React, { useState, useEffect } from 'react';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/16/solid';
import { supabase } from '../../supabaseClient';

const GeneralTab = ({ workspaceId, metrics, calculatePercentageChange, groupedCurrentDeviceCategories, groupedSourceMedium, searchConsoleData }) => {
  const [websiteStatus, setWebsiteStatus] = useState(null);
  const [uptimePercentage, setUptimePercentage] = useState(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);

  useEffect(() => {
    const fetchWebsiteStatus = async () => {
      setIsLoadingStatus(true);

      if (!workspaceId) {
        console.error('Workspace ID is not defined');
        setIsLoadingStatus(false);
        return;
      }

      const { data, error } = await supabase
        .from('workspaces')
        .select('uptime_robot_api_key')
        .eq('id', workspaceId)
        .single();

      if (error) {
        console.error('Error fetching API key:', error.message);
        setIsLoadingStatus(false);
        return;
      }

      const apiKey = data?.uptime_robot_api_key;

      if (apiKey) {
        try {
          const response = await fetch('https://api.uptimerobot.com/v2/getMonitors', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              'api_key': apiKey,
              'format': 'json',
              'custom_uptime_ratios': '30',  // Laat de API het uptime-percentage van de laatste 30 dagen teruggeven
            }),
          });

          const result = await response.json();

          if (result.stat === 'ok') {
            const monitor = result.monitors[0];
            setWebsiteStatus(monitor.status === 2 ? 'Online' : 'Offline');
            setUptimePercentage(Math.round(monitor.custom_uptime_ratio.split('-')[0]));  // Rond af op hele procenten
          } else {
            console.error('Failed to fetch uptime status:', result.error.message);
          }
        } catch (err) {
          console.error('Error fetching uptime status:', err.message);
        }
      } else {
        console.error('No API key found');
      }

      setIsLoadingStatus(false);
    };

    fetchWebsiteStatus();
  }, [workspaceId]);

  return (
    <div>
      {metrics.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {/* Website Status Card */}
          <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
            <div className="flex justify-between items-center">
              <h2 className="font-medium text-zinc-900">Website status</h2>
            </div>
            <div className="mt-3 text-3xl font-semibold sm:text-2xl">
              {isLoadingStatus ? 'Loading...' : websiteStatus}
            </div>
            <div className="mt-3 text-sm sm:text-xs flex items-center">
              <span className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${websiteStatus === 'Online' ? 'bg-lime-400/20 text-lime-700' : 'bg-pink-400/15 text-pink-700'}`}>
                {websiteStatus === 'Online' ? (
                  <>
                    <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                    {uptimePercentage !== null && `${uptimePercentage}%`}
                  </>
                ) : (
                  <>
                    <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                    {uptimePercentage !== null && `${uptimePercentage}%`}
                  </>
                )}
              </span>
            </div>
          </div>

          {/* Existing Cards */}
          <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
            <div className="flex justify-between items-center">
              <h2 className="font-medium text-zinc-900">Actieve gebruikers</h2>
            </div>
            <div className="mt-3 text-3xl font-semibold sm:text-2xl">
              {Math.round(metrics[0]?.metricValues[0]?.value)}
            </div>
            <div className="mt-3 text-sm sm:text-xs flex items-center">
              <span
                className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${
                  calculatePercentageChange(
                    metrics[0]?.metricValues[0]?.value,
                    metrics[1]?.metricValues[0]?.value
                  ) >= 0
                    ? 'bg-lime-400/20 text-lime-700'
                    : 'bg-pink-400/15 text-pink-700'
                }`}
              >
                {calculatePercentageChange(
                  metrics[0]?.metricValues[0]?.value,
                  metrics[1]?.metricValues[0]?.value
                ) >= 0 ? (
                  <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                ) : (
                  <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                )}
                {Math.abs(
                  calculatePercentageChange(
                    metrics[0]?.metricValues[0]?.value,
                    metrics[1]?.metricValues[0]?.value
                  )
                ).toFixed(1)}
                %
              </span>
              <span className="text-zinc-500 ml-1"> </span>
            </div>
          </div>

          <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
            <div className="flex justify-between items-center">
              <h2 className="font-medium text-zinc-900">Nieuwe gebruikers</h2>
            </div>
            <div className="mt-3 text-3xl font-semibold sm:text-2xl">
              {Math.round(metrics[0]?.metricValues[1]?.value)}
            </div>
            <div className="mt-3 text-sm sm:text-xs flex items-center">
              <span
                className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${
                  calculatePercentageChange(
                    metrics[0]?.metricValues[1]?.value,
                    metrics[1]?.metricValues[1]?.value
                  ) >= 0
                    ? 'bg-lime-400/20 text-lime-700'
                    : 'bg-pink-400/15 text-pink-700'
                }`}
              >
                {calculatePercentageChange(
                  metrics[0]?.metricValues[1]?.value,
                  metrics[1]?.metricValues[1]?.value
                ) >= 0 ? (
                  <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                ) : (
                  <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                )}
                {Math.abs(
                  calculatePercentageChange(
                    metrics[0]?.metricValues[1]?.value,
                    metrics[1]?.metricValues[1]?.value
                  )
                ).toFixed(1)}
                %
              </span>
              <span className="text-zinc-500 ml-1"> </span>
            </div>
          </div>

          <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
            <div className="flex justify-between items-center">
              <h2 className="font-medium text-zinc-900">Paginaweergaven</h2>
            </div>
            <div className="mt-3 text-3xl font-semibold sm:text-2xl">
              {Math.round(metrics[0]?.metricValues[2]?.value)}
            </div>
            <div className="mt-3 text-sm sm:text-xs flex items-center">
              <span
                className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${
                  calculatePercentageChange(
                    metrics[0]?.metricValues[2]?.value,
                    metrics[1]?.metricValues[2]?.value
                  ) >= 0
                    ? 'bg-lime-400/20 text-lime-700'
                    : 'bg-pink-400/15 text-pink-700'
                }`}
              >
                {calculatePercentageChange(
                  metrics[0]?.metricValues[2]?.value,
                  metrics[1]?.metricValues[2]?.value
                ) >= 0 ? (
                  <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                ) : (
                  <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                )}
                {Math.abs(
                  calculatePercentageChange(
                    metrics[0]?.metricValues[2]?.value,
                    metrics[1]?.metricValues[2]?.value
                  )
                ).toFixed(1)}
                %
              </span>
              <span className="text-zinc-500 ml-1"> </span>
            </div>
          </div>

          <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
            <div className="flex justify-between items-center">
              <h2 className="font-medium text-zinc-900">Sessies</h2>
            </div>
            <div className="mt-3 text-3xl font-semibold sm:text-2xl">
              {Math.round(metrics[0]?.metricValues[3]?.value)}
            </div>
            <div className="mt-3 text-sm sm:text-xs flex items-center">
              <span
                className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${
                  calculatePercentageChange(
                    metrics[0]?.metricValues[3]?.value,
                    metrics[1]?.metricValues[3]?.value
                  ) >= 0
                    ? 'bg-lime-400/20 text-lime-700'
                    : 'bg-pink-400/15 text-pink-700'
                }`}
              >
                {calculatePercentageChange(
                  metrics[0]?.metricValues[3]?.value,
                  metrics[1]?.metricValues[3]?.value
                ) >= 0 ? (
                  <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                ) : (
                  <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                )}
                {Math.abs(
                  calculatePercentageChange(
                    metrics[0]?.metricValues[3]?.value,
                    metrics[1]?.metricValues[3]?.value
                  )
                ).toFixed(1)}
                %
              </span>
              <span className="text-zinc-500 ml-1"> </span>
            </div>
          </div>

          <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
            <div className="flex justify-between items-center">
              <h2 className="font-medium text-zinc-900">Bouncepercentage</h2>
            </div>
            <div className="mt-3 text-3xl font-semibold sm:text-2xl">
              {Math.round(metrics[0]?.metricValues[4]?.value * 100)}%
            </div>
            <div className="mt-3 text-sm sm:text-xs flex items-center">
              <span
                className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${
                  calculatePercentageChange(
                    metrics[0]?.metricValues[4]?.value,
                    metrics[1]?.metricValues[4]?.value
                  ) < 0
                    ? 'bg-lime-400/20 text-lime-700'
                    : 'bg-pink-400/15 text-pink-700'
                }`}
              >
                {calculatePercentageChange(
                  metrics[0]?.metricValues[4]?.value,
                  metrics[1]?.metricValues[4]?.value
                ) >= 0 ? (
                  <ArrowUpIcon className="h-3 w-3 text-pink-700" />
                ) : (
                  <ArrowDownIcon className="h-3 w-3 text-lime-700" />
                )}
                {Math.abs(
                  calculatePercentageChange(
                    metrics[0]?.metricValues[4]?.value,
                    metrics[1]?.metricValues[4]?.value
                  )
                ).toFixed(1)}
                %
              </span>
              <span className="text-zinc-500 ml-1"> </span>
            </div>
          </div>

          <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
            <div className="flex justify-between items-center">
              <h2 className="font-medium text-zinc-900">Gemiddelde sessieduur</h2>
            </div>
            <div className="mt-3 text-3xl font-semibold sm:text-2xl">
              {Math.round(metrics[0]?.metricValues[5]?.value)} seconden
            </div>
            <div className="mt-3 text-sm sm:text-xs flex items-center">
              <span
                className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${
                  calculatePercentageChange(
                    metrics[0]?.metricValues[5]?.value,
                    metrics[1]?.metricValues[5]?.value
                  ) >= 0
                    ? 'bg-lime-400/20 text-lime-700'
                    : 'bg-pink-400/15 text-pink-700'
                }`}
              >
                {calculatePercentageChange(
                  metrics[0]?.metricValues[5]?.value,
                  metrics[1]?.metricValues[5]?.value
                ) >= 0 ? (
                  <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                ) : (
                  <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                )}
                {Math.abs(
                  calculatePercentageChange(
                    metrics[0]?.metricValues[5]?.value,
                    metrics[1]?.metricValues[5]?.value
                  )
                ).toFixed(1)}
                %
              </span>
              <span className="text-zinc-500 ml-1"> </span>
            </div>
          </div>

          {/* Apparaatcategorieën weergave */}
          {Object.keys(groupedCurrentDeviceCategories).length > 0 && (
            <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
              <h2 className="font-medium text-zinc-900 mb-2">
                Gebruikers per apparaat
              </h2>
              <table className="min-w-full divide-y divide-zinc-200 dark:divide-zinc-700">
                <tbody className="bg-white dark:bg-zinc-900 divide-y divide-zinc-200 dark:divide-zinc-700">
                  {Object.entries(groupedCurrentDeviceCategories).map(
                    ([category, { current, previous }], index) => {
                      const percentageChange = calculatePercentageChange(
                        current,
                        previous
                      );

                      return (
                        <tr key={index}>
                          <td className="px-0 py-3 whitespace-nowrap text-sm text-zinc-900 dark:text-white">
                            {category.charAt(0).toUpperCase() +
                              category.slice(1)}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm text-zinc-500 dark:text-zinc-400">
                            {current}
                          </td>
                          <td className="px-0 py-3 whitespace-nowrap text-sm text-zinc-500 dark:text-zinc-400 text-right">
                            <span
                              className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${
                                percentageChange >= 0
                                  ? 'bg-lime-400/20 text-lime-700'
                                  : 'bg-pink-400/15 text-pink-700'
                              }`}
                            >
                              {percentageChange >= 0 ? (
                                <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                              ) : (
                                <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                              )}
                              {Math.abs(percentageChange).toFixed(1)}%
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* Verkeersbronnen weergave */}
          {Object.keys(groupedSourceMedium).length > 0 && (
            <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
              <h2 className="font-medium text-zinc-900 mb-2">
                Gebruikers per verkeersbron
              </h2>
              <table className="min-w-full divide-y divide-zinc-200 dark:divide-zinc-700">
                <tbody className="bg-white dark:bg-zinc-900 divide-y divide-zinc-200 dark:divide-zinc-700">
                  {Object.entries(groupedSourceMedium).map(
                    ([source, { current, previous }], index) => {
                      const percentageChange = calculatePercentageChange(
                        current,
                        previous
                      );

                      return (
                        <tr key={index}>
                          <td className="px-0 py-3 whitespace-nowrap text-sm text-zinc-900 dark:text-white">
                            {source.charAt(0).toUpperCase() + source.slice(1)}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm text-zinc-500 dark:text-zinc-400">
                            {current}
                          </td>
                          <td className="px-0 py-3 whitespace-nowrap text-sm text-zinc-500 dark:text-zinc-400 text-right">
                            <span
                              className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${
                                percentageChange >= 0
                                  ? 'bg-lime-400/20 text-lime-700'
                                  : 'bg-pink-400/15 text-pink-700'
                              }`}
                            >
                              {percentageChange >= 0 ? (
                                <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                              ) : (
                                <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                              )}
                              {Math.abs(percentageChange).toFixed(1)}%
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* Search Console weergave */}
          {searchConsoleData && searchConsoleData.length > 0 && (
            <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
              <h2 className="font-medium text-zinc-900 mb-2">
                Top 10 zoekwoorden met positie
              </h2>
              <table className="min-w-full divide-y divide-zinc-200 dark:divide-zinc-700">
                <tbody className="bg-white dark:bg-zinc-900 divide-y divide-zinc-200 dark:divide-zinc-700">
                  {searchConsoleData.slice(0, 10).map((row, index) => {
                    const currentPosition = Math.round(row.position.current);
                    const previousPosition = Math.round(
                      row.position.previous
                    );
                    const positionChange =
                      currentPosition - previousPosition;

                    return (
                      <tr key={index}>
                        <td className="px-0 py-3 whitespace-nowrap text-sm text-zinc-900 dark:text-white">
                          {row.query}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm text-zinc-500 dark:text-zinc-400 text-right">
                          {currentPosition} {/* Positie afgerond op hele getallen */}
                        </td>
                        <td className="px-0 py-3 whitespace-nowrap text-sm text-zinc-500 dark:text-zinc-400 text-right">
                          <span
                            className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${
                              positionChange > 0
                                ? 'bg-pink-400/20 text-pink-700'
                                : positionChange < 0
                                ? 'bg-lime-400/15 text-lime-700'
                                : 'bg-sky-400/20 text-sky-700'
                            }`}
                          >
                            {positionChange > 0 && (
                              <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                            )}
                            {positionChange < 0 && (
                              <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                            )}
                            {Math.abs(positionChange)} {/* Verandering in absolute getallen */}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ) : (
        <p className="mt-4">Loading Google Analytics data...</p>
      )}
    </div>
  );
};

export default GeneralTab;
