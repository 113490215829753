import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Navbar, NavbarItem, NavbarSection, NavbarDivider, NavbarSpacer, NavbarLabel } from './catalyst/navbar';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu, DropdownDivider } from './catalyst/dropdown';
import { ChevronDownIcon, InboxIcon, UserIcon, Cog8ToothIcon, LightBulbIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/16/solid';
import AddWorkspaceDialog from './AddWorkspaceDialog';

const defaultNavItems = [
  { label: 'Workspaces', url: '/' },
  { label: 'Gebruikers', url: '/users' },
];

const workspaceNavItems = [
  { label: 'Dashboard', url: '/start' }, // Basispad voor workspace overzicht
  { label: 'Inzichten', url: '' }, // Basispad voor workspace overzicht
  { label: 'Taken', url: '/tasks' }, // Basispad voor workspace instellingen
  { label: 'Facturering', url: '/tasks' }, // Basispad voor workspace instellingen
  { label: 'Instellingen', url: '/settings' }, // Basispad voor workspace instellingen
];

const TeamDropdownMenu = ({ workspaces, currentWorkspaceId }) => {
  const filteredWorkspaces = workspaces.filter(ws => ws.id !== currentWorkspaceId);

  return (
    <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
      {filteredWorkspaces.map((workspace) => (
        <DropdownItem key={workspace.id} href={`/${workspace.id}`}>
          {workspace.name}
        </DropdownItem>
      ))}
      <DropdownDivider />
      <DropdownItem href="/">
        Workspace Overzicht
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarMenu = () => {
  const location = useLocation();
  const { id } = useParams(); // Haal het workspace ID op uit de URL, indien aanwezig
  const [workspaces, setWorkspaces] = useState([]);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data: workspaceUsers, error: workspaceUserError } = await supabase
          .from('workspace_users')
          .select('workspace_id')
          .eq('user_id', user.id);
        
        if (workspaceUserError) {
          console.error('Error fetching workspace access:', workspaceUserError.message);
          return;
        }

        const workspaceIds = workspaceUsers.map(wu => wu.workspace_id);

        if (workspaceIds.length > 0) {
          const { data: workspacesData, error: workspaceError } = await supabase
            .from('workspaces')
            .select('*')
            .in('id', workspaceIds);

          if (workspaceError) {
            console.error('Error fetching workspaces:', workspaceError.message);
          } else {
            setWorkspaces(workspacesData);
            if (id) {
              const currentWs = workspacesData.find(ws => ws.id === id);
              setCurrentWorkspace(currentWs);
            }
          }
        }
      }
    };

    fetchWorkspaces();
  }, [id]);

  const navItems = id ? workspaceNavItems : defaultNavItems;

  return (
    <>
      <Navbar>
        <Dropdown>
          <DropdownButton as={NavbarItem}>
            <NavbarLabel>{currentWorkspace ? currentWorkspace.name : 'Loile Workflow'}</NavbarLabel>
            <ChevronDownIcon />
          </DropdownButton>
          <TeamDropdownMenu
            workspaces={workspaces}
            currentWorkspaceId={id}
            onAddWorkspaceClick={() => setIsAddDialogOpen(true)}
          />
        </Dropdown>
        <NavbarDivider />
        <NavbarSection>
          {navItems.map(({ label, url }) => (
            <NavbarItem 
              key={label} 
              href={id ? `/${id}${url}` : url} // Bouw de correcte URL op met /:id/settings
              current={location.pathname === `/${id}${url}`} // Controleer of de huidige URL overeenkomt
            >
              {label}
            </NavbarItem>
          ))}
        </NavbarSection>
        <NavbarSpacer />
        <NavbarSection>
          <NavbarItem href="/inbox" aria-label="Inbox">
            <InboxIcon />
          </NavbarItem>
          <Dropdown>
            <DropdownButton as={NavbarItem}>
              <UserIcon />
            </DropdownButton>
            <DropdownMenu className="min-w-64" anchor="bottom end">
              <DropdownItem href="/profile">
                <UserIcon />
                Mijn profiel
              </DropdownItem>
              <DropdownItem href="/settings">
                <Cog8ToothIcon />
                Instellingen
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem href="/share-feedback">
                <LightBulbIcon />
                Share feedback
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem href="/logout">
                <ArrowRightStartOnRectangleIcon />
                Sign out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarSection>
      </Navbar>

      <AddWorkspaceDialog
        isOpen={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        onWorkspaceAdded={() => { /* handle reloading workspaces */ }}
      />
    </>
  );
};

export default NavbarMenu;
