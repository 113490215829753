import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import axios from 'axios';
import GeneralTab from './GeneralTab';
import { startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { Dropdown, DropdownButton, DropdownMenu } from '../../components/catalyst/dropdown';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';

const Overview = () => {
  const { id } = useParams();
  const [workspace, setWorkspace] = useState(null);
  const [loading, setLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [searchConsoleData, setSearchConsoleData] = useState(null);
  const [activeTab, setActiveTab] = useState('Algemeen');

  const today = new Date();
  const lastMonth = subMonths(today, 1);
  const previousMonth = subMonths(today, 2);

  const currentStartDate = startOfMonth(lastMonth);
  const currentEndDate = endOfMonth(lastMonth);
  const previousStartDate = startOfMonth(previousMonth);
  const previousEndDate = endOfMonth(previousMonth);

  const [currentDateRange, setCurrentDateRange] = useState({
    from: currentStartDate,
    to: currentEndDate,
  });

  const [compareDateRange, setCompareDateRange] = useState({
    from: previousStartDate,
    to: previousEndDate,
  });

  const fetchAnalyticsData = useCallback(async (propertyId) => {
    try {
      if (!currentDateRange.from || !currentDateRange.to || !compareDateRange.from || !compareDateRange.to) {
        console.warn('Please select both a current and a comparison date range');
        return;
      }

      const formatDate = (date) => date.toISOString().split('T')[0];

      const currentStartDate = formatDate(currentDateRange.from);
      const currentEndDate = formatDate(currentDateRange.to);
      const compareStartDate = formatDate(compareDateRange.from);
      const compareEndDate = formatDate(compareDateRange.to);

      const response = await axios.get('https://workflow-backend-bice.vercel.app/api/analytics', {
        params: {
          propertyId,
          startDate: currentStartDate,
          endDate: currentEndDate,
          compareStartDate: compareStartDate,
          compareEndDate: compareEndDate,
        },
      });

      setAnalyticsData(response.data);
    } catch (error) {
      console.error('Error fetching Google Analytics data:', error);
    }
  }, [currentDateRange, compareDateRange]);

  const fetchSearchConsoleData = useCallback(async (siteUrl) => {
    try {
      if (!currentDateRange.from || !currentDateRange.to || !compareDateRange.from || !compareDateRange.to) {
        console.warn('Please select both a current and a comparison date range');
        return;
      }

      const formatDate = (date) => date.toISOString().split('T')[0];

      const currentStartDate = formatDate(currentDateRange.from);
      const currentEndDate = formatDate(currentDateRange.to);
      const compareStartDate = formatDate(compareDateRange.from);
      const compareEndDate = formatDate(compareDateRange.to);

      const response = await axios.get('https://workflow-backend-bice.vercel.app/api/search-console', {
        params: {
          siteUrl,
          startDate: currentStartDate,
          endDate: currentEndDate,
          compareStartDate: compareStartDate,
          compareEndDate: compareEndDate,
        },
      });

      setSearchConsoleData(response.data);
    } catch (error) {
      console.error('Error fetching Google Search Console data:', error);
    }
  }, [currentDateRange, compareDateRange]);

  useEffect(() => {
    const fetchWorkspaceAndUser = async () => {
      try {
        const { data: workspaceData } = await supabase
          .from('workspaces')
          .select('*')
          .eq('id', id)
          .single();

        setWorkspace(workspaceData);
        
        if (workspaceData.google_analytics_property_id) {
          await fetchAnalyticsData(workspaceData.google_analytics_property_id);
        } else {
          console.warn('No Google Analytics property ID found.');
        }

        if (workspaceData.google_search_console_site_url) {
          await fetchSearchConsoleData(workspaceData.google_search_console_site_url);
        } else {
          console.warn('No Google Search Console site URL found.');
        }

      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkspaceAndUser();
  }, [id, fetchAnalyticsData, fetchSearchConsoleData]);

  const calculatePercentageChange = (current, previous) => {
    current = current || 0;
    previous = previous || 0;

    if (previous === 0) return current === 0 ? 0 : 100;

    return ((current - previous) / previous) * 100;
  };

  if (loading) {
    return <div>Inzichten laden...</div>;
  }

  if (!workspace) {
    return <div>Workspace not found</div>;
  }

  const deviceCategory = analyticsData?.deviceCategoryData?.rows || [];
  const sourceMedium = analyticsData?.sourceMediumData?.rows || [];

  const groupedCurrentDeviceCategories = deviceCategory.reduce((acc, row) => {
    const category = row.dimensionValues[0].value;
    const isCurrentPeriod = row.dimensionValues[1].value === 'date_range_0';

    if (isCurrentPeriod) {
      acc[category] = {
        current: parseFloat(row.metricValues[0]?.value) || 0,
        previous: acc[category]?.previous || 0,
      };
    } else {
      acc[category] = {
        current: acc[category]?.current || 0,
        previous: parseFloat(row.metricValues[0]?.value) || 0,
      };
    }

    return acc;
  }, {});

  const groupedSourceMedium = sourceMedium.reduce((acc, row) => {
    const source = row.dimensionValues[0].value;
    const isCurrentPeriod = row.dimensionValues[1].value === 'date_range_0';

    if (isCurrentPeriod) {
      acc[source] = {
        current: parseFloat(row.metricValues[0]?.value) || 0,
        previous: acc[source]?.previous || 0,
      };
    } else {
      acc[source] = {
        current: acc[source]?.current || 0,
        previous: parseFloat(row.metricValues[0]?.value) || 0,
      };
    }

    return acc;
  }, {});

  // Functie om de inhoud van het actieve tabblad te renderen
  const renderContent = () => {
    switch (activeTab) {
      case 'Algemeen':
        return (
          <GeneralTab
            workspaceId={id}
            metrics={analyticsData?.metricsData?.rows || []}
            calculatePercentageChange={calculatePercentageChange}
            groupedCurrentDeviceCategories={groupedCurrentDeviceCategories}
            groupedSourceMedium={groupedSourceMedium}
            searchConsoleData={searchConsoleData}
          />
        );
      // Voeg hier andere cases toe voor andere tabbladen als die er zijn
      default:
        return null;
    }
  };

  return (
    <div className="p-4">
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-zinc-950/10 pb-4 mb-4 dark:border-white/10">
        <div>
          <h1 className="text-2xl font-bold">
            Inzichten
          </h1>
          <p className="text-zinc-600">Website: {workspace.website_url}</p>
        </div>
        <Dropdown>
          <DropdownButton outline>
            <div className="text-left">
              <span className="block font-medium text-base text-right leading-tight">
                {format(currentDateRange.from, 'dd MMM yyyy', { locale: nl })} - {format(currentDateRange.to, 'dd MMM yyyy', { locale: nl })}
              </span>
              <span className="block font-light text-xs leading-tight text-right text-zinc-500">
                vs. {format(compareDateRange.from, 'dd MMM yyyy', { locale: nl })} - {format(compareDateRange.to, 'dd MMM yyyy', { locale: nl })}
              </span>
            </div>
            <ChevronDownIcon />
          </DropdownButton>
          <DropdownMenu anchor="bottom end">
            <div className="pl-8 pb-8 pt-8">
              <div className="flex space-x-4">
                <div>
                  <h2 className="text-lg font-bold mb-2">Datumselectie</h2>
                  <DayPicker
                    mode="range"
                    locale={nl}
                    selected={currentDateRange}
                    onSelect={setCurrentDateRange}
                    className="mt-2 mb-4"
                    defaultMonth={currentStartDate}
                  />
                </div>
                <div>
                  <h2 className="text-lg font-bold mb-2">Vergelijken met</h2>
                  <DayPicker
                    mode="range"
                    locale={nl}
                    selected={compareDateRange}
                    onSelect={setCompareDateRange}
                    className="mt-2 mb-4"
                    defaultMonth={previousStartDate}
                  />
                </div>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>

      <div className="border-b border-zinc-950/10 dark:border-white/10 mb-4">
        <ul className="flex space-x-6">
          <li
            className={`cursor-pointer pb-2 ${activeTab === 'Algemeen' ? 'border-b-2 border-emerald-600' : ''}`}
            onClick={() => setActiveTab('Algemeen')}
          >
            Website
          </li>
          <li
            className={`cursor-pointer pb-2 ${activeTab === 'Social' ? 'border-b-2 border-emerald-600' : ''}`}
            onClick={() => setActiveTab('Social')}
          >
            Socials
          </li>
          <li
            className={`cursor-pointer pb-2 ${activeTab === 'Technical' ? 'border-b-2 border-emerald-600' : ''}`}
            onClick={() => setActiveTab('Technical')}
          >
            Campagnes
          </li>
        </ul>
      </div>

      <div>
        {renderContent()}
      </div>
    </div>
  );
};

export default Overview;
